import { Query, sendRequest } from 'best-modules/utils'
import {
  CheckDebtorData,
  CheckDebtorInfo,
  DebtorData,
} from '@/utils/types/checkDebtor'
import { PaginationList } from 'best-modules/components/server-table/types'

const checkDebtor = (
  payload: CheckDebtorData
): Promise<{ data: DebtorData }> => {
  return sendRequest({
    name: 'checkDebtor',
    method: 'post',
    url: '/admin/debtorCheck/check',
    payload,
  })
}

const checkDebtorList = (
  query: Query
): Promise<PaginationList<CheckDebtorInfo>> => {
  return sendRequest({
    name: 'checkDebtorList',
    method: 'get',
    url: {
      url: '/admin/debtorCheck',
      query,
    },
  })
}

const checkDebtorSingle = (checkDebtorId: string): Promise<CheckDebtorInfo> => {
  return sendRequest({
    name: 'checkDebtorSingle',
    method: 'get',
    url: `/admin/debtorCheck/single/${checkDebtorId}`,
  })
}

export { checkDebtor, checkDebtorList, checkDebtorSingle }
