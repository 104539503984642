<template>
  <v-card elevation="1">
    <v-card-text>
      <div class="d-flex justify-end">
        <v-btn
          v-if="userAccessRights.includes('company.create')"
          class="mb-3"
          @click="createCompany">
          <v-icon class="mr-2">mdi-plus</v-icon>
          Створити компанію
        </v-btn>
      </div>
      <b-server-table
        :headers="companyListHeaders"
        :request-func="getCompanyList"
        show-search
        show-filters
        @click:row="
          e => {
            if (userAccessRights.includes('company.single')) {
              $router.push({
                name: 'company-single',
                params: { id: e.id },
              })
            }
          }
        ">
        <template #filters>
          <b-server-filter-item
            v-slot="{ props }"
            query-key="typeId"
            directory="companyTypes">
            <filter-select label="Тип" :select-props="props"></filter-select>
          </b-server-filter-item>
        </template>
        <template #[`item.actions`]="{ item }">
          <b-action-menu
            :actions="[
              {
                title: 'Відкрити',
                icon: 'mdi-eye',
                action: () => {
                  if (userAccessRights.includes('company.single')) {
                    $router.push({
                      name: 'company-single',
                      params: { id: item.id },
                    })
                  }
                },
              },
              {
                title: 'Видалити',
                disabled: userAccessRights.includes('company.delete'),
                icon: 'mdi-delete',
                action: () => deleteCompany(item.id),
              },
            ]"></b-action-menu>
        </template>
        <template #bottom></template>
      </b-server-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import FilterSelect from '@/components/inputs/filter-select.vue'
import {
  getCompanyList,
  deleteCompany as deleteCompanyRequest,
} from '@/request/company'
import { openDialog } from '@/plugins/dialog'
import { inject } from 'vue'
import { rerenderKey } from '@/injection-keys'
import {
  BActionMenu,
  BServerTable,
  BServerFilterItem,
} from 'best-modules/components'
import { companyListHeaders } from '@/utils/tableHeaders'
import { useRouter } from 'vue-router'
import { useUser } from '@/store/user'
import { storeToRefs } from 'pinia'

export default {
  name: 'CompanyList',
  components: { FilterSelect, BActionMenu, BServerTable, BServerFilterItem },
  data: () => {
    return {
      companyListHeaders,
    }
  },
  setup() {
    const router = useRouter()
    const rerender = inject(rerenderKey)

    const { userAccessRights } = storeToRefs(useUser())

    const createCompany = () => {
      openDialog({
        name: 'Company',
        params: {
          cb: company => {
            router.push({ name: 'company-single', params: { id: company.id } })
          },
        },
      })
    }

    const deleteCompany = (companyId: string) => {
      return deleteCompanyRequest(companyId).then(rerender)
    }

    return { createCompany, deleteCompany, getCompanyList, userAccessRights }
  },
}
</script>
