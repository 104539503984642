<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <b-input
          v-model="user.surname"
          label="Прізвище"
          hide-details
          placeholder="Введіть текст"
          :error-messages="getErrorMessages(v$.surname)"
          @blur="v$.surname.$touch()"></b-input>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <b-input
          v-model="user.name"
          label="Ім'я"
          hide-details
          placeholder="Введіть текст"
          :error-messages="getErrorMessages(v$.name)"
          @blur="v$.name.$touch()"></b-input>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <b-input
          v-model="user.patronymic"
          placeholder="Введіть текст"
          hide-details
          label="По батькові"></b-input>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <b-autocomplete
          v-if="!dialog.dialogData.isCreateCompanyRole"
          v-model="user.companyId"
          label="Компанія"
          :items="$directory.get('companyList')"
          item-value="id"
          item-title="shortName"
          hide-details
          placeholder="оберіть компанію"
          :loading="$loading.isLoading('companyList')"
          :error-messages="getErrorMessages(v$.companyId)"
          @blur="v$.companyId.$touch()"
          @focus="$directory.fill('companyList')"></b-autocomplete>
      </v-col>
      <v-col
        v-if="!dialog.dialogData.isCreateCompanyRole"
        cols="12"
        md="12"
        sm="12"
        class="pt-0 mb-3">
        <b-input-password
          v-model="user.password"
          label="Пароль"
          hide-details
          :error-messages="getErrorMessages(v$.password)"
          @blur="v$.password.$touch()"></b-input-password>
      </v-col>
    </v-row>

    <v-row class="mt-0 mb-3">
      <v-col cols="12" md="6" sm="12" class="pt-0">
        <b-input-phone
          v-model="user.phone"
          label="Телефон"
          placeholder="Введіть номер"
          hide-details
          :error-messages="getErrorMessages(v$.phone)"
          @blur="v$.phone.$touch()"></b-input-phone>
      </v-col>
      <v-col cols="12" md="6" sm="12" class="pt-0">
        <b-input
          v-model="user.email"
          v-mask:[emailMask]
          label="Email"
          type="email"
          placeholder="Введіть пошту"
          hide-details
          :error-messages="getErrorMessages(v$.email)"
          @blur="v$.email.$touch()"></b-input>
      </v-col>
    </v-row>

    <div
      v-if="dialog.dialogData.isCreateCompanyRole"
      class="d-flex align-center gap-10 mb-5">
      <div
        class="access"
        :style="{
          border: `${user.role === 'adminCompany' ? '1px solid blue' : '1px solid #e7e7e7'}`,
        }">
        <div class="access_wrapper">
          <div class="d-flex align-center mb-3">
            <v-radio-group v-model="user.role" hide-details>
              <v-radio color="primary" value="adminCompany">
                <template #label>
                  <h4 class="text-black">Перегляд і керування</h4>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
          <div class="access_wrapper_info">
            <div class="d-flex align-center">
              <v-icon class="mr-1" color="green">mdi-check</v-icon>
              <span>Переглядати всю інформацію по пакетам</span>
            </div>
            <div>
              <div class="d-flex mt-2 align-center">
                <v-icon class="mr-1" color="green">mdi-check</v-icon>
                <span>Керувати пакетами</span>
              </div>
              <div class="d-flex flex-column" style="padding-left: 28px">
                <span>- Створювати пакет</span>
                <span>- Підписувати та відправляти дані до НБУ</span>
                <span>- Перевіряти статус</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="access"
        :style="{
          border: `${user.role === 'userCompany' ? '1px solid blue' : '1px solid #e7e7e7'}`,
        }">
        <div class="access_wrapper">
          <div class="d-flex align-center mb-3">
            <v-radio-group v-model="user.role" hide-details>
              <v-radio color="primary" value="userCompany">
                <template #label>
                  <h4 class="text-black">Тільки перегляд</h4>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
          <div class="access_wrapper_info">
            <div class="d-flex align-center">
              <v-icon class="mr-1" color="green">mdi-check</v-icon>
              <span>Переглядати всю інформацію по пакетам</span>
            </div>
            <div>
              <div class="d-flex mt-2 align-center">
                <v-icon class="mr-1" color="red">mdi-close</v-icon>
                <span>Керувати пакетами</span>
              </div>
              <div class="d-flex flex-column" style="padding-left: 28px">
                <span>- Створювати пакет</span>
                <span>- Підписувати та відправляти дані до НБУ</span>
                <span>- Перевіряти статус</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { createUser } from '@/request/user'
import { reactive } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, email } from '@vuelidate/validators'
import { BInputPhone, BInputPassword } from 'best-modules/components'
import { getErrorMessages } from 'best-modules/utils'
import { emailMask } from 'best-modules/utils'
import type { CompanyUserRole, UserCredentials } from '@/utils/types'
import { createUserCompanyRole } from '@/request/company'

export default {
  name: 'User',
  components: { BInputPhone, BInputPassword },
  props: {
    dialog: { type: Object },
  },
  data: () => {
    return {
      emailMask,
    }
  },
  methods: { getErrorMessages },
  setup(props) {
    const user = reactive<UserCredentials & CompanyUserRole>(
      {} as UserCredentials & CompanyUserRole
    )
    const rules = {
      name: { required },
      surname: { required },
      phone: { required, minLength: minLength(23) },
      email: { required, email },
      password: props.dialog.dialogData.isCreateCompanyRole ? {} : { required },
      companyId: props.dialog.dialogData.isCreateCompanyRole
        ? {}
        : { required },
      role: props.dialog.dialogData.isCreateCompanyRole ? { required } : {},
    }

    const v$ = useVuelidate(rules, user)

    const submit = () => {
      if (props.dialog.dialogData.isCreateCompanyRole) {
        return createUserCompanyRole(
          user as CompanyUserRole,
          props?.dialog?.dialogData?.companyId
        )
      } else {
        return createUser(user as UserCredentials)
      }
    }

    return { user, submit, v$ }
  },
}
</script>

<style lang="scss" scoped>
.access {
  cursor: default;
  width: 50%;
  border-radius: 4px;

  &_wrapper {
    padding: 15px;
    &_info {
      padding-left: 20px;
      span {
        font-size: 13px;
      }
    }
  }
}
</style>
