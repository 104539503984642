<template>
  <div>
    <b-section-loader
      :loading="$loading.isLoading(['fillPackageDirectory', 'nbuCreditTypes'])">
      <div v-if="debtorData.status === 'Found'">
        <div v-if="toggleTab === 'cr-version-1'">
          <div v-if="debtorData.result_kvi.length">
            <v-card
              v-for="(resultKvi, index) in debtorData.result_kvi"
              :key="resultKvi.orderBank"
              elevation="1"
              :class="{ 'mt-3': index !== 0 }">
              <v-card-text>
                <CustomExpansionPanel
                  :label="`Результат пошуку №${resultKvi.orderBank}`">
                  <template #default>
                    <div>
                      <TableValue title="Ідентифікаційні дані боржника">
                        <template #field>
                          <div v-if="resultKvi.nameUr">
                            <ItemValue
                              v-for="(item, index) in getResultKviCompany(
                                resultKvi
                              )"
                              :key="index"
                              :label="item.label"
                              :coefficient="item.coefficient"
                              :directory="item.directory"
                              :value="item.value">
                            </ItemValue>
                          </div>
                          <div v-if="resultKvi.fio">
                            <ItemValue
                              v-for="(item, index) in getResultKviPerson(
                                resultKvi
                              )"
                              :key="index"
                              :label="item.label"
                              :coefficient="item.coefficient"
                              :directory="item.directory"
                              :value="item.value">
                            </ItemValue>
                          </div>
                        </template>
                      </TableValue>
                      <CustomExpansionPanel>
                        <template #label>
                          <h4>
                            Кредитні операції та виконання зобов’язань за
                            кредитними операціями
                          </h4>
                        </template>
                        <template #default>
                          <CustomExpansionPanel
                            v-for="(credit, creditIdx) in resultKvi.credits"
                            :key="creditIdx"
                            border>
                            <template #label>
                              <h4>
                                {{
                                  $directory
                                    .get('nbuCreditTypes', null, {
                                      type: 'collection',
                                    })
                                    .getItem(credit.typeCredit)
                                    ?.name.replace(/^./, char =>
                                      char.toUpperCase()
                                    )
                                }}
                              </h4>
                            </template>
                            <template #default>
                              <div>
                                <TableValue>
                                  <template #field>
                                    <div class="d-flex flex-column gap-1">
                                      <ItemValue
                                        label="Номер договору"
                                        :value="credit.numberDog">
                                      </ItemValue>
                                      <ItemValue
                                        label="Дата укладання договору"
                                        :value="credit.dogDay">
                                      </ItemValue>
                                      <ItemValue
                                        label="Кінцева дата погашення кредиту/наданого фінансового зобов’язання"
                                        :value="credit.endDay">
                                      </ItemValue>
                                      <ItemValue
                                        label="Загальна сума (ліміт кредитної лінії/овердрафту) наданого фінансового зобов’язання"
                                        :value="credit.sumZagal"
                                        :coefficient="10 ** 2">
                                      </ItemValue>
                                      <ItemValue
                                        label="Валюта"
                                        :value="credit.r030"
                                        directory="R030">
                                      </ItemValue>
                                      <!-- table -->
                                      <v-divider class="my-2"></v-divider>
                                      <ItemValue
                                        is-table
                                        :items="[
                                          {
                                            label: 'Строкова заборгованість',
                                            valueMain: credit.sumArrearsBase,
                                            valueInterest:
                                              credit.sumArrearsProc,
                                            coefficient: 10 ** 2,
                                          },
                                          {
                                            label: 'Прострочена заборгованість',
                                            valueMain: credit.arrearBase,
                                            valueInterest: credit.arrearProc,
                                            coefficient: 10 ** 2,
                                          },
                                          {
                                            label:
                                              'Кількість днів прострочення',
                                            valueMain: credit.dayBase,
                                            valueInterest: credit.dayProc,
                                          },
                                        ]">
                                      </ItemValue>
                                    </div>
                                  </template>
                                </TableValue>
                                <CustomExpansionPanel
                                  v-if="credit.tranche.length"
                                  label="Транші">
                                  <template #default>
                                    <div v-if="credit.tranche.length">
                                      В розробці
                                    </div>
                                    <div
                                      v-else
                                      class="text-disabled text-center">
                                      Транші відсутні
                                    </div>
                                  </template>
                                </CustomExpansionPanel>
                              </div>
                            </template>
                          </CustomExpansionPanel>
                        </template>
                      </CustomExpansionPanel>
                      <div>
                        <CustomExpansionPanel v-if="resultKvi.pledge.length">
                          <template #label>
                            <h4>Застава</h4>
                          </template>
                          <template #default>
                            <v-data-table
                              :items="resultKvi.pledge"
                              :headers="debtorPledgeHeaders"
                              item-value="orderZastava">
                              <template #item.s031="{ item }">
                                <span v-if="item.s031">
                                  {{
                                    directory.S031.getItem(item.s031)?.name ||
                                    item.s031
                                  }}
                                </span>
                                <span v-else class="text-disabled"
                                  >Не вказано</span
                                >
                              </template>
                              <template #item.pledgeDay="{ item }">
                                <span v-if="item.pledgeDay">
                                  {{
                                    new CustomDate(item.pledgeDay).toString()
                                  }}
                                </span>
                                <span v-else class="text-disabled"
                                  >Не вказано</span
                                >
                              </template>
                            </v-data-table>
                          </template>
                        </CustomExpansionPanel>
                        <h4 v-else>Застава (0)</h4>
                      </div>
                    </div>
                  </template>
                </CustomExpansionPanel>
              </v-card-text>
            </v-card>
          </div>
          <InfoNotFound v-else></InfoNotFound>
        </div>
        <div v-if="toggleTab === 'cr-version-2'">
          <div v-if="debtorData.info_creditor.length">
            <v-card
              v-for="(infoCreditor, idx) in debtorData.info_creditor"
              :key="idx"
              elevation="1"
              :class="{ 'mt-3': idx !== 0 }">
              <v-card-text>
                <CustomExpansionPanel :label="infoCreditor.creditor">
                  <template #default>
                    <div>
                      <TableValue title="Ідентифікаційні дані боржника">
                        <template #field>
                          <div v-if="infoCreditor.ent_person">
                            <ItemValue
                              v-for="(item, index) in ent_person(infoCreditor)"
                              :key="index"
                              :label="item.label"
                              :coefficient="item.coefficient"
                              :directory="item.directory"
                              :value="item.value">
                            </ItemValue>
                          </div>
                          <div v-if="infoCreditor.ind_person">
                            <ItemValue
                              v-for="(item, index) in ind_person(infoCreditor)"
                              :key="index"
                              :label="item.label"
                              :value="item.value">
                            </ItemValue>
                          </div>
                        </template>
                      </TableValue>
                      <v-divider class="my-5"></v-divider>
                      <CustomExpansionPanel
                        v-for="(credit, creditIdx) in infoCreditor.credit"
                        :key="creditIdx">
                        <template #label>
                          <h4>
                            Кредитні операції та виконання зобов’язань за
                            кредитними операціями №{{ creditIdx + 1 }}
                          </h4>
                        </template>
                        <template #default>
                          <CustomExpansionPanel
                            v-for="(loan, loanIdx) in credit.loan || []"
                            :key="loanIdx"
                            border>
                            <template #label>
                              <h4>Активна операція</h4>
                            </template>
                            <template #default>
                              <TableValue>
                                <template #field>
                                  <ItemValue
                                    v-for="(item, index) in credit_loan(loan)"
                                    :key="index"
                                    :label="item.label"
                                    :coefficient="item.coefficient"
                                    :directory="item.directory"
                                    :value="item.value">
                                  </ItemValue>
                                </template>
                              </TableValue>
                            </template>
                          </CustomExpansionPanel>
                          <CustomExpansionPanel v-if="credit.liability" border>
                            <template #label>
                              <h4>Фінансове зобов'язання</h4>
                            </template>
                            <template #default>
                              <TableValue>
                                <template #field>
                                  <ItemValue
                                    v-for="(item, index) in credit_liability(
                                      credit
                                    )"
                                    :key="index"
                                    :label="item.label"
                                    :coefficient="item.coefficient"
                                    :directory="item.directory"
                                    :value="item.value">
                                  </ItemValue>
                                </template>
                              </TableValue>
                            </template>
                          </CustomExpansionPanel>
                        </template>
                      </CustomExpansionPanel>
                    </div>
                  </template>
                </CustomExpansionPanel>
              </v-card-text>
            </v-card>
          </div>
          <InfoNotFound v-else></InfoNotFound>
        </div>
      </div>

      <div v-if="debtorData.status === 'NotFound'">
        <InfoNotFound></InfoNotFound>
      </div>
      <div v-if="debtorData.status === 'LegallyWithheld'">
        Знайдена інформація не може бути надана у зв’язку із встановленими
        законодавством обмеженнями
      </div>
    </b-section-loader>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { BSectionLoader } from 'best-modules/components'
import { CustomDate, rankSpacing } from 'best-modules/utils'
import { usePackageDirectory } from '@/store/package-directory'
import { DebtorData } from '@/utils/types/checkDebtor'
import { useDebtorResult } from './helpers'
import { fillDirectory } from '@/directory'
import { debtorPledgeHeaders } from '@/utils/tableHeaders'
import CustomExpansionPanel from '@/pages/check-debtor/components/CustomExpansionPanel.vue'
import TableValue from '@/pages/check-debtor/components/TableValue.vue'
import ItemValue from '@/pages/check-debtor/components/ItemValue.vue'
import InfoNotFound from '@/pages/check-debtor/components/InfoNotFound.vue'

export default {
  name: 'CheckDebtorResult',
  components: {
    InfoNotFound,
    ItemValue,
    TableValue,
    CustomExpansionPanel,
    BSectionLoader,
  },
  props: {
    debtorData: {
      type: Object as PropType<DebtorData>,
    },
    toggleTab: {
      type: String,
    },
  },
  data() {
    return {
      debtorPledgeHeaders,
    }
  },
  methods: { rankSpacing, CustomDate },
  setup() {
    const { fillDirectory: fillPackageDirectory, directory } =
      usePackageDirectory()

    fillPackageDirectory()
    fillDirectory(['nbuCreditTypes', 'debtorCheckResult'])

    return {
      directory,
      ...useDebtorResult(),
    }
  },
}
</script>

<style scoped lang="scss"></style>
