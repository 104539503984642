<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems" class="px-0 pt-0 pb-0">
      <template #prepend>
        <div>
          <HomeIcon class="mr-2" @click="$router.push({ name: 'home' })" />
        </div>
      </template>
      <template #divider>
        <v-icon>{{ 'mdi-chevron-right' }}</v-icon>
      </template>
      <template #item="{ item }">
        <v-breadcrumbs-item
          v-if="item.title"
          :title="item.title"
          :class="{
            'cursor-pointer': item.to,
          }"
          @click="item.to && $router.push(item.to)"></v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <div v-if="cardLabel" class="mt-5 cursor-default">
      <component :is="cardLabel" />
    </div>
  </div>
</template>

<script>
import { useBreadcrumb } from '@/plugins/breadcrumb.js'
import { computed } from 'vue'
import HomeIcon from '@/assets/images/HomeIcon.vue'

export default {
  components: { HomeIcon },
  setup() {
    const { items, cardLabel } = useBreadcrumb()
    const home = { icon: 'mdi-home-circle', href: { name: 'home' } }
    const breadcrumbItems = computed(() => items.value || [])
    return { home, breadcrumbItems, items, cardLabel }
  },
}
</script>

<style lang="scss" scoped></style>
