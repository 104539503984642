import { EntPerson } from '@/utils/types/checkDebtor'
import { usePackageDirectory } from '@/store/package-directory'
import { rankSpacing, CustomDate } from 'best-modules/utils'
import { PackageDirectoryName } from '@/store/package-directory/types'
import { getPersonName } from 'best-modules/utils/index'

const useDebtorResult = () => {
  const { directory } = usePackageDirectory()

  const getAddressString = (entPerson: EntPerson) => {
    const country = directory.K040.getItem(entPerson.k040_reg_country)?.name
    const region = directory.KODTER.getItem(entPerson.kodter_region)?.name
    const cityType = directory.FLOC.getItem(
      entPerson.floc_settlement_category
    )?.name
    const city = [cityType, entPerson.locality].filter(Boolean).join(' ')

    const streetType = directory.FSTR.getItem(entPerson.fstr_street_type)?.name
    const street = [streetType, entPerson.street].filter(Boolean).join(' ')

    return [
      country,
      entPerson.zip,
      region,
      entPerson.district,
      city,
      street,
      entPerson.building,
      entPerson.corpus,
      entPerson.facilities,
    ]
      .filter(Boolean)
      .join(', ')
  }

  const getValue = (
    value: number | string | boolean | null,
    directoryName?: PackageDirectoryName,
    coefficient?: number
  ): string => {
    switch (true) {
      case !['number', 'boolean'].includes(typeof value) && !value:
        return '<span class="text-disabled">Не вказано</span>'
      case !!directoryName:
        return directory[directoryName].getItem(value as string)?.name || ''
      case typeof value === 'boolean':
        return value ? 'Так' : 'Ні'
      case typeof value === 'number': {
        const formattedValue = coefficient
          ? (value / (coefficient || 1)).toFixed(2)
          : value / (coefficient || 1)
        return rankSpacing(formattedValue, {
          forceDecimal: !!coefficient,
          decimal: 2,
        })
      }
      case typeof value === 'string' &&
        value.length === 10 &&
        new CustomDate(value).isValid:
        return new CustomDate(value).toString()
      default:
        return value as string
    }
  }

  const getName = checkDebtorInfo => {
    const resultKvi = checkDebtorInfo?.resultData?.data?.result_kvi?.[0]

    if (resultKvi?.nameUr) {
      return resultKvi.nameUr
    }

    if (resultKvi?.fio && Object.keys(resultKvi.fio || {}).length > 0) {
      return getPersonName(resultKvi.fio, {
        keyNames: {
          name: 'firstName',
          surname: 'lastName',
          patronymic: 'middleName',
        },
      })
    }

    const infoCreditor = checkDebtorInfo?.resultData?.data?.info_creditor?.[0]
    if (
      infoCreditor?.ind_person &&
      Object.keys(infoCreditor.ind_person || {}).length > 0
    ) {
      return getPersonName(infoCreditor.ind_person, {
        keyNames: {
          name: 'first_name',
          surname: 'last_name',
          patronymic: 'patronymic',
        },
      })
    }

    if (infoCreditor?.ent_person?.[0]?.full_name_entity) {
      return infoCreditor.ent_person[0].full_name_entity
    }

    return null
  }

  const ent_person = infoCreditor => {
    return [
      {
        label: 'Повна назва',
        value: infoCreditor?.ent_person?.full_name_entity,
      },
      {
        label: 'ЄДРПОУ',
        value: infoCreditor?.ent_person?.entity_code,
      },
      {
        label: 'Адреса',
        value: useDebtorResult().getAddressString(infoCreditor.ent_person),
      },
    ]
  }

  const ind_person = infoCreditor => {
    return [
      {
        label: 'ПІБ',
        value: [
          infoCreditor.ind_person.last_name,
          infoCreditor.ind_person.first_name,
          infoCreditor.ind_person.patronymic,
        ]
          .filter(Boolean)
          .join(' '),
      },
      {
        label: 'Дата народження',
        value: infoCreditor?.ind_person?.birth_date,
      },
      {
        label: 'Реєстраційний номер облікової картки платника податків',
        value: infoCreditor.ind_person.ind_person_code,
      },
      {
        label: 'Паспорт',
        value: infoCreditor.ind_person.passport,
      },
    ]
  }

  const credit_loan = loan => {
    return [
      {
        label: 'Дата укладення договору',
        value: loan.agreem_start_date,
      },
      {
        label: 'Дата закінчення договору',
        value: loan.agreem_end_date,
      },
      {
        label: 'Номер договору',
        value: loan.agreem_no,
      },
      {
        label: 'Заборгованість за кредитною операцією',
        value: loan.balance_on,
        coefficient: 10 ** 2,
      },
      {
        label: 'Кількість днів прострочення',
        value: loan.days_overdue,
      },
      {
        label: 'Основний борг списаний за рахунок сформованих резервів',
        value: loan.debt_wr_on,
        coefficient: 10 ** 2,
      },
      {
        label: 'Нараховані доходи списані за рахунок сформованих резервів',
        value: loan.inc_wr_on,
        coefficient: 10 ** 2,
      },
      {
        label: 'Вид активної операції',
        value: loan.f037_loan_type,
        directory: 'F037',
      },
      {
        label: 'Розмір простроченої заборгованості',
        value: loan.overdue_amount,
        coefficient: 10 ** 2,
      },
      {
        label: 'Валюта',
        value: loan.r030_currency,
        directory: 'R030',
      },
      {
        label: 'Загальна сума кредиту відповідно до договору',
        value: loan.total_amount,
        coefficient: 10 ** 2,
      },
      {
        label:
          'Дата повного/часткового списання заборгованості за рахунок сформованих резервів та продовження їх обліку на позабалансових рахунках',
        value: loan.write_off_date,
      },
    ]
  }

  const credit_liability = credit => {
    return [
      {
        label: 'Номер договору',
        value: credit.liability.agreem_no,
      },
      {
        label: 'Дата укладення договору',
        value: credit.liability.agreem_start_date,
      },
      {
        label: 'Дата припинення фінансового зобов’язання',
        value: credit.liability.obligation_end_date,
      },
      {
        label: 'Залишок фінансового зобов’язання',
        value: credit.liability.balance_off,
        coefficient: 10 ** 2,
      },
      {
        label: 'Кількість днів прострочення',
        value: credit.liability.days_overdue,
      },
      {
        label: 'Вид активної операції',
        value: credit.liability.f037_loan_type,
        directory: 'F037',
      },
      {
        label: 'Розмір простроченої заборгованості',
        value: credit.liability.overdue_amount,
        coefficient: 10 ** 2,
      },
      {
        label: 'Валюта',
        value: credit.liability.r030_currency,
        directory: 'R030',
      },
      {
        label:
          'Загальна сума наданого фінансового зобов’язання відповідно до договору',
        value: credit.liability.total_amount,
        coefficient: 10 ** 2,
      },
    ]
  }

  const getResultKviCompany = resultKvi => {
    return [
      {
        label: 'Назва',
        value: resultKvi.nameUr,
      },
      {
        label: 'ЄДРПОУ',
        value: resultKvi.codEdrpou,
      },
      {
        label:
          'Інформація про належність боржника до пов’язаних із кредитодавцем осіб',
        value: resultKvi.flagK060,
      },
    ]
  }

  const getResultKviPerson = resultKvi => {
    return [
      {
        label: 'ПІБ',
        value: [
          resultKvi.fio.firstName,
          resultKvi.fio.lastName,
          resultKvi.fio.middleName,
        ]
          .filter(Boolean)
          .join(' '),
      },
      {
        label: 'Дата народження',
        value: resultKvi.birthDay,
      },
      {
        label: 'Ідентифікатор Боржника',
        value: resultKvi.inn,
      },
    ]
  }

  const resultKvi_credits = credit => {
    return [
      {
        label: 'Номер договору',
        value: credit.numberDog,
      },
      {
        label: 'Дата укладання договору',
        value: credit.dogDay,
      },
      {
        label:
          'Кінцева дата погашення кредиту/наданого фінансового зобов’язання',
        value: credit.endDay,
      },
      {
        label:
          'Загальна сума (ліміт кредитної лінії/овердрафту) наданого фінансового зобов’язання',
        value: credit.sumZagal,
        coefficient: 10 ** 2,
      },
      {
        label: 'Валюта',
        value: credit.r030,
        directory: 'R030',
      },
      {
        label: 'Залишок заборгованості за кредитною операцією',
        value: credit.sumArrears,
        coefficient: 10 ** 2,
      },
    ]
  }

  return {
    getName,
    getAddressString,
    getValue,
    ent_person,
    ind_person,
    credit_loan,
    credit_liability,
    getResultKviCompany,
    getResultKviPerson,
    resultKvi_credits,
  }
}

export { useDebtorResult }
