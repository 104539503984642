<template>
  <div>
    <check-debtor-list
      v-if="$route.name === 'check-debtor-list'"></check-debtor-list>
    <router-view v-else></router-view>
  </div>
</template>

<script lang="ts">
import CheckDebtorList from './CheckDebtorList.vue'
export default {
  name: 'CheckDebtorIndex',
  components: { CheckDebtorList },
}
</script>

<style scoped lang="scss"></style>
