<template>
  <v-card elevation="12">
    <v-card-text>
      <h4>Відправка здійснюється на тестове середовище НБУ</h4>
      <span>
        Нагадуємо, що з 01.01.2025 року дані про Боржника та активні операції
        подавати на продуктове середовище НБУ Продуктове середовище знаходиться
        за посиланням
        <a href="https://cr-nbu.com.ua" class="link d-inline" target="_blank">
          https://cr-nbu.com.ua
        </a>
      </span>
      <div class="d-flex justify-end">
        <v-btn variant="plain" @click="closeDialog('prodAlert')">
          Закрити
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { openDialogKey, closeDialogKey } from './injectionKeys'
import { inject } from 'vue'
export default {
  name: 'ProdAlert',
  setup() {
    const openDialog = inject(openDialogKey)
    const closeDialog = inject(closeDialogKey)
    if (location.hostname !== 'cr-nbu.com.ua') {
      openDialog('prodAlert')
    }

    return { closeDialog }
  },
}
</script>

<style scoped lang="scss"></style>
