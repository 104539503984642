<template>
  <div v-if="isTable">
    <div class="table-header d-grid">
      <div></div>
      <div class="text-end font-weight-bold">за основним боргом</div>
      <div class="text-end font-weight-bold">за процентами</div>
    </div>

    <div v-for="(item, index) in items" :key="index" class="table-row d-grid">
      <div style="color: #656567">{{ item.label }}</div>

      <div
        class="text-end"
        v-html="
          getValue(item.valueMain, item.directory, item.coefficient)
        "></div>

      <div
        class="text-end"
        v-html="
          getValue(item.valueInterest, item.directory, item.coefficient)
        "></div>
    </div>
  </div>
  <div v-else>
    <div class="d-flex gap-3 align-center">
      <div class="w-50" style="color: #656567">
        {{ label }}
      </div>
      <div
        class="w-50 text-end"
        style="color: #1f1f1f"
        v-html="getValue(value, directory, coefficient)"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { useDebtorResult } from '../single/helpers'
import { PropType } from 'vue'
import { PackageDirectoryName } from '@/store/package-directory/types'

export default {
  name: 'ItemValue',
  props: {
    label: {
      type: String,
    },
    value: {
      type: [Number, String, Boolean],
    },
    directory: {
      type: String as PropType<PackageDirectoryName>,
    },
    coefficient: {
      type: Number,
    },
    isTable: {
      type: Boolean,
    },
    items: {
      type: Array,
    },
  },
  setup() {
    return { ...useDebtorResult() }
  },
}
</script>

<style scoped lang="scss">
.d-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  gap: 0.5rem;
}
</style>
