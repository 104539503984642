import { MenuItem } from 'best-modules/components/sidebar-menu/types'

const menuItems: MenuItem[] = [
  {
    label: 'Головна',
    icon: 'mdi-home-outline',
    to: { name: 'home' },
  },
  {
    label: 'Звітність',
    icon: 'mdi-file-settings-outline',
    to: { name: 'report-list' },
    access: user => {
      return user?.watch_company_user_role?.repository?.includes(
        'report.getAll'
      )
    },
  },
  {
    label: 'Перевірка боржника',
    icon: 'mdi-file-check-outline',
    access: user => {
      return (
        user?.watch_company_user_role?.repository?.includes(
          'debtorCheck.check'
        ) ||
        user?.watch_company_user_role?.repository?.includes('debtorCheck.check')
      )
    },
    children: [
      {
        label: 'Пошук',
        icon: 'mdi-text-search-variant',
        to: { name: 'check-debtor-search' },
        access: user => {
          return user?.watch_company_user_role?.repository?.includes(
            'debtorCheck.check'
          )
        },
      },
      {
        label: 'Список запитів',
        icon: 'mdi-format-list-bulleted',
        to: { name: 'check-debtor-list' },
        access: user => {
          return user?.watch_company_user_role?.repository?.includes(
            'debtorCheck.getAll'
          )
        },
      },
    ],
  },
  {
    label: 'Інтеграція',
    icon: 'mdi-file-code-outline',
    to: { name: 'integration' },
  },
  {
    label: 'Тариф',
    icon: 'mdi-currency-uah',
    to: { name: 'tarif' },
  },
  {
    label: 'Довідка',
    icon: 'mdi-help',
    to: { name: 'directory' },
  },
  {
    label: 'Налаштування компанії',
    icon: 'mdi-cog-outline',
    to: {
      name: 'user-company',
    },
    access: user => {
      if (
        user &&
        user.watch_company_user_role &&
        user.watch_company_user_role.repository
      ) {
        return user.watch_company_user_role.repository.includes(
          'company.single'
        )
      } else {
        return false
      }
    },
  },
  {
    label: 'Адміністрування',
    icon: 'mdi-account-supervisor-circle',
    access: user => user.isAdmin,
    children: [
      {
        label: 'Користувачі',
        icon: 'mdi-account-multiple',
        to: { name: 'user-list' },
        access: user => {
          if (
            user &&
            user.watch_company_user_role &&
            user.watch_company_user_role.repository
          ) {
            return user.watch_company_user_role.repository.includes(
              'user.getAll'
            )
          } else {
            return false
          }
        },
      },
      {
        label: 'Компанії',
        icon: 'mdi-domain',
        to: { name: 'company-list' },
        access: user => {
          if (
            user &&
            user.watch_company_user_role &&
            user.watch_company_user_role.repository
          ) {
            return user.watch_company_user_role.repository.includes(
              'company.getAll'
            )
          } else {
            return false
          }
        },
      },
    ],
  },
]

export { menuItems }
