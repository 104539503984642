<template>
  <div class="mb-5">
    <div v-if="photoPath" class="d-flex align-center justify-center">
      <BaseImage
        :width="300"
        :height="300"
        aspect-ratio="1"
        rounded
        :src="photoPath" />
    </div>

    <b-input-file
      v-else
      v-model="photo"
      title="Фото"
      dropper
      :multiple="false"
      :accept="['.jpg', '.jpeg', '.png', '.webp', '.svg']"
      type="paths"
      nameFolder="фото"
      :showFiles="false"
      mainFolder="Пользователи"
      label="Оберіть нове фото">
    </b-input-file>
  </div>
</template>

<script>
import { BInputFile } from 'best-modules/components'
import { computed, ref } from 'vue'
import BaseImage from '@/components/BaseImage.vue'

export default {
  components: {
    BaseImage,
    BInputFile,
  },
  setup() {
    const photo = ref(null)

    const photoPath = computed(() => {
      if (photo.value && photo.value[0]) {
        return `${photo.value}`
      }
      return null
    })

    const submit = () => {
      return photo.value
    }

    return {
      photo,
      photoPath,
      submit,
    }
  },
}
</script>

<style scoped lang="scss"></style>
