import Default from '@/layouts/default/Default.vue'
import NotFoundLayout from '@/layouts/NotFoundLayout.vue'
import Auth from '@/layouts/Auth.vue'
import Login from '@/pages/auth/Login.vue'
import ResetPassword from '@/pages/auth/ResetPassword.vue'
import AuthConfirm from '@/pages/auth/AuthConfirm.vue'
import Home from '@/pages/home/Home.vue'
import UserList from '@/pages/users/Index.vue'
import UserSingle from '@/pages/users/UserSingle.vue'
import ReportIndex from '@/pages/report/Index.vue'
import ReportSingle from '@/pages/report/ReportSingle.vue'
import PackageSingle from '@/pages/report/package/PackageSingle.vue'
import CompanyList from '@/pages/companies/Index.vue'
import CompanySingle from '@/pages/companies/single/CompanySingle.vue'
import IntegrationIndex from '@/pages/integration/Index.vue'
import DirectoryIndex from '@/pages/directory/Index.vue'
import TarifIndex from '@/pages/tarif/Index.vue'
import UserProfile from '@/pages/profile/UserProfile.vue'
import CheckDebtor from '@/pages/check-debtor/form/CheckDebtor.vue'
import CheckDebtorList from '@/pages/check-debtor/Index.vue'
import CheckDebtorSingle from '@/pages/check-debtor/single/CheckDebtorSingle.vue'

export default [
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundLayout,
    name: 'notFound',
    meta: {
      title: 'Сторінку не знайдено',
    },
  },
  {
    path: '/',
    name: 'auth',
    component: Auth,
    redirect: { name: 'login' },
    meta: {
      title: 'CR 2.0 NBU',
    },
    children: [
      {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
          title: 'Авторизація',
        },
      },
      {
        path: '/auth-confirm',
        name: 'auth-confirm',
        component: AuthConfirm,
        meta: {
          title: 'Авторизація',
        },
      },
      {
        path: '/reset-password',
        name: 'reset-password',
        component: ResetPassword,
        meta: {
          title: 'Відновлення пароля',
        },
      },
    ],
  },
  {
    path: '/main',
    name: 'main',
    component: Default,
    redirect: { name: 'home' },
    meta: {
      title: 'CR 2.0 NBU',
    },
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
          title: 'Головна',
        },
      },
      {
        path: '/report',
        name: 'report-list',
        component: ReportIndex,
        meta: {
          title: 'Звітність',
        },
        children: [
          {
            path: ':id',
            name: 'report-single',
            component: ReportSingle,
            meta: {
              title: 'Звітність',
              watchParams: true,
            },
          },
          {
            path: 'package/:id',
            name: 'package-single',
            component: PackageSingle,
            meta: {
              title: 'Пакет',
              watchParams: true,
              confirmLeavingGuard: true,
            },
          },
        ],
      },
      {
        path: '/check-debtor-search',
        name: 'check-debtor-search',
        component: CheckDebtor,
        meta: {
          title: 'Перевірка боржника',
        },
      },
      {
        path: '/check-debtor',
        name: 'check-debtor-list',
        component: CheckDebtorList,
        meta: {
          title: 'Перевірка боржника',
        },
        children: [
          {
            path: ':id',
            name: 'check-debtor-single',
            component: CheckDebtorSingle,
            meta: {
              title: 'Перевірка боржника',
            },
          },
        ],
      },
      {
        path: '/integration',
        name: 'integration',
        component: IntegrationIndex,
        meta: {
          title: 'Інтеграція',
        },
      },
      {
        path: '/directory',
        name: 'directory',
        component: DirectoryIndex,
        meta: {
          title: 'Довідка',
        },
      },
      {
        path: '/tarif',
        name: 'tarif',
        component: TarifIndex,
        meta: {
          title: 'Тариф',
        },
      },
      {
        path: '/user-company',
        name: 'user-company',
        component: CompanySingle,
        meta: {
          title: 'Налаштування компанії',
        },
      },
      {
        path: '/profile',
        name: 'profile',
        component: UserProfile,
        meta: {
          title: 'Профіль',
        },
      },
      {
        path: '/users',
        name: 'user-list',
        component: UserList,
        meta: {
          title: 'Користувачі',
        },
        children: [
          {
            path: ':id',
            name: 'user-single',
            component: UserSingle,
            meta: {
              title: 'Користувач',
              watchParams: true,
              access: (user, route) => {
                return (
                  user.watch_company_user_role.repository.includes(
                    'user.single'
                  ) || user.id === route.params.id
                )
              },
            },
          },
        ],
      },
      {
        path: '/companies',
        name: 'company-list',
        component: CompanyList,
        meta: {
          title: 'Компанії',
        },
        access: user => {
          return user.watch_company_user_role.repository.includes(
            'company.getAll'
          )
        },
        children: [
          {
            path: ':id',
            name: 'company-single',
            component: CompanySingle,
            meta: {
              title: 'Компанія',
              watchParams: true,
              access: (user, route) => {
                return (
                  user.watch_company_user_role.repository.includes(
                    'company.single'
                  ) || user.companyId === route.params.id
                )
              },
            },
          },
        ],
      },
    ],
  },
]
