<template>
  <div>
    <BFileDropper
      :label="label"
      :title="title"
      :files="localFiles"
      :accept="accept"
      :select-options="{ multiple, accept }"
      @select="handleSelect($event)">
      <template v-if="$slots['dropper-label']" #dropper-label>
        <slot name="dropper-label"></slot>
      </template>
    </BFileDropper>
  </div>
</template>

<script lang="ts">
import { BFileDropper } from 'best-modules/components'
import { PropType } from 'vue'
import { FileAccept } from 'best-modules/components/inputs/input-file/selectFile/types'

export default {
  components: { BFileDropper },
  props: {
    multiple: { type: Boolean, default: true },
    label: { type: String },
    title: { type: String },
    localFiles: { type: Array },
    accept: { type: Array as PropType<FileAccept[]> },
    handleSelect: { type: Function },
  },
}
</script>

<style scoped lang="scss"></style>
