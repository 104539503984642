<template>
  <div>
    <b-section-loader :loading="$loading.isLoading('getCheckDebtorSingle')">
      <v-row class="cursor-default">
        <v-col :cols="12" :md="4">
          <v-card elevation="1">
            <v-card-text>
              <h4 class="mb-2">Бриф</h4>
              <div class="mb-2">
                <div class="text-disabled">Тип боржника</div>
                <div>{{ checkDebtorInfo.debtor_type.name }}</div>
              </div>
              <div class="mb-2">
                <div class="text-disabled">Причина перевірки</div>
                <div>{{ checkDebtorInfo.reason.name }}</div>
              </div>
              <v-divider class="my-3"></v-divider>
              <div class="mb-2">
                <div class="text-disabled">
                  Належність запитувача до учасників державних програм з
                  підтримки кредитування
                </div>
                <div>{{ checkDebtorInfo.stateProgram ? 'Так' : 'Ні' }}</div>
              </div>
              <div class="mb-2">
                <div class="text-disabled">Тип пошуку</div>
                <div>{{ checkDebtorInfo.credential.name }}</div>
              </div>
              <div class="mb-2">
                <div class="text-disabled">Значення пошуку</div>
                <div>{{ checkDebtorInfo.credentialValue }}</div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="12" :md="8" :class="!mobile ? 'pl-0' : null">
          <check-debtor-result
            :toggleTab="toggleTab"
            :debtor-data="
              checkDebtorInfo.resultData.data
            "></check-debtor-result>
        </v-col>
        <v-col cols="12" md="12" sm="12" class="pt-0">
          <v-card elevation="1">
            <v-card-text>
              <span class="label">
                Увага! Цей звіт не є офіційним документом і надається з метою
                ознайомлення фізичної особи з даними щодо неї, які містяться в
                Кредитному реєстрі Національного банку України. Цей звіт не може
                використовуватися як документ, що подається в державні установи
                та інші організації для підтвердження інформації про кредитні
                зобов'язання або будь-якої іншої інформації.
                <br />
                <br />
                З метою отримання інформації з Кредитного реєстру Національного
                банку України, що є офіційним документом, зверніться до
                Національного банку України в порядку, передбаченому в абзацах
                другому та третьому пункту 22 розділу V Положення про Кредитний
                реєстр Національного банку України, затвердженого постановою
                Правління Національного банку України від 04.05.2018 № 50 (зі
                змінами).
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </b-section-loader>
  </div>
</template>

<script lang="ts">
import { checkDebtorSingle } from '@/request/checkDebtor'
import { computed, reactive, ref, watch } from 'vue'
import { CheckDebtorInfo } from '@/utils/types/checkDebtor'
import { useRoute } from 'vue-router'
import { BSectionLoader } from 'best-modules/components'
import { handleAsync } from 'best-modules/plugins'
import CheckDebtorResult from './CheckDebtorResult.vue'
import { useBreadcrumb } from '@/plugins/breadcrumb'
import BreadcrumbHeader from '@/pages/check-debtor/components/BreadcrumbHeader.vue'
import { useDisplay } from 'vuetify'
import { useDebtorResult } from '@/pages/check-debtor/single/helpers'

export default {
  name: 'CheckDebtorSingle',
  components: { BSectionLoader, CheckDebtorResult },
  setup() {
    const route = useRoute()
    const { mobile } = useDisplay()
    const toggleTab = ref('cr-version-1')
    const breadcrumb = useBreadcrumb()
    const checkDebtorId = computed<string>(() => route.params.id as string)
    const checkDebtorInfo = reactive<CheckDebtorInfo>({} as CheckDebtorInfo)
    const { getName } = useDebtorResult()

    watch(
      () => getName(checkDebtorInfo),
      value => {
        if (value) {
          breadcrumb.setCardLabel({
            component: BreadcrumbHeader,
            props: {
              nameUr: value,
              onUpdateToggleTab: val => {
                toggleTab.value = val
              },
            },
          })
        }
      },
      { immediate: true }
    )

    handleAsync('getCheckDebtorSingle', () => {
      return checkDebtorSingle(checkDebtorId.value).then(res => {
        Object.assign(checkDebtorInfo, res)
      })
    })

    return { checkDebtorInfo, toggleTab, mobile, ...useDebtorResult() }
  },
}
</script>

<style scoped lang="scss"></style>
