<template>
  <div
    class="cursor-default d-flex gap-4"
    style="
      background: #eaeef466;
      padding: 12px;
      border-radius: 4px;
      border-left: 1px solid #3bad49;
      max-width: fit-content;
    ">
    <span>Подання заяви: ~ <strong>2 хвилини</strong></span>
    <span>Надання послуги: <strong>миттєво</strong></span>
    <span>Вартість: <strong>безкоштовно</strong></span>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss"></style>
