<template>
  <v-card elevation="1">
    <v-card-text>
      <b-section-loader
        :loading="
          $loading.isLoading([
            'debtorTypes',
            'checkReason',
            'debtorCredentials',
          ])
        ">
        <div>
          <div>
            <h4 class="pl-2 pb-2">1.Причина перевірки</h4>
            <v-radio-group
              v-model="checkDebtorData.reasonId"
              density="comfortable">
              <v-radio
                v-for="r in $directory.get('checkReason')"
                :key="r.id"
                :value="r.id"
                :label="r.name">
              </v-radio>
            </v-radio-group>
          </div>

          <div>
            <h4 class="pl-2 pb-2">
              2.Належність запитувача до учасників державних програм з підтримки
              кредитування
            </h4>
            <v-radio-group
              v-model="checkDebtorData.stateProgram"
              density="comfortable">
              <v-radio label="Так" :value="true"></v-radio>
              <v-radio label="Ні" :value="false"></v-radio>
            </v-radio-group>
          </div>

          <div>
            <h4 class="pl-2 pb-2">3.Тип боржника</h4>
            <v-radio-group
              v-model="checkDebtorData.debtorTypeId"
              density="comfortable">
              <v-radio
                v-for="t in $directory.get('debtorTypes')"
                :key="t.id"
                :value="t.id"
                :label="t.name">
              </v-radio>
            </v-radio-group>
          </div>

          <div>
            <h4 class="pl-2 pb-2">4.Тип пошуку</h4>
            <v-radio-group
              v-model="checkDebtorData.credentialId"
              density="comfortable">
              <v-radio
                v-for="k in filteredCredentials"
                :key="k.id"
                :value="k.id"
                :label="k.name">
              </v-radio>
            </v-radio-group>
          </div>

          <b-input
            v-model="checkDebtorData.credentialValue"
            :placeholder="credentialPlaceholder"
            :mask="credentialMask"
            :error-messages="getErrorMessages(v$.credentialValue)"
            @blur="v$.credentialValue.$touch()">
            <template #append>
              <v-btn @click="checkDebtor"> Пошук </v-btn>
            </template>
          </b-input>
        </div>
      </b-section-loader>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { computed, onMounted, reactive, watch } from 'vue'
import { fillDirectory, getDirectory } from '@/directory'
import { BSectionLoader } from 'best-modules/components'
import { credentialsValidationMap } from './credentialsValidation'
import { openDialog } from '@/plugins/dialog'
import { minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { getErrorMessages } from 'best-modules/utils'
import { useRouter } from 'vue-router'
import { useBreadcrumb } from '@/plugins/breadcrumb'
import BreadcrumbHeader from '@/pages/check-debtor/form/BreadcrumbHeader.vue'

export default {
  name: 'CheckDebtor',
  components: { BSectionLoader },
  methods: { getErrorMessages },
  setup() {
    const router = useRouter()
    const breadcrumb = useBreadcrumb()
    const checkDebtorData = reactive({
      debtorTypeId: 1,
      reasonId: 1,
      stateProgram: false,
      credentialId: 1,
      credentialValue: '',
    })

    onMounted(() => {
      breadcrumb.setCardLabel({
        component: BreadcrumbHeader,
      })
    })

    const filteredCredentials = computed(() => {
      const credentialsByDebtorTypeId = {
        1: [1, 2, 3, 4],
        2: [1, 5],
        3: [6],
        4: [5],
      }
      return getDirectory('debtorCredentials').filter(k => {
        return credentialsByDebtorTypeId[checkDebtorData.debtorTypeId].includes(
          k.id as number
        )
      })
    })

    const credentialMask = computed(() => {
      return credentialsValidationMap[checkDebtorData.credentialId].mask
    })

    const credentialPlaceholder = computed(() => {
      return credentialsValidationMap[checkDebtorData.credentialId].placeholder
    })

    watch(
      () => checkDebtorData.debtorTypeId,
      () => {
        checkDebtorData.credentialId = filteredCredentials.value[0].id as number
        checkDebtorData.credentialValue = ''
        v$.value.$reset()
      }
    )

    const rules = {
      credentialValue: {
        required: required,
        minLength: minLength(
          computed(
            () =>
              credentialsValidationMap[checkDebtorData.credentialId].minLength
          )
        ),
      },
    }

    const v$ = useVuelidate(rules, checkDebtorData)

    const checkDebtor = () => {
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      openDialog({
        name: 'Signature',
        dialogData: {
          isCheckDebtor: true,
          debtorData: checkDebtorData,
        },
        params: {
          cb: res => {
            router.push({ name: 'check-debtor-single', params: { id: res.id } })
          },
        },
      })
    }

    fillDirectory(['debtorTypes', 'checkReason', 'debtorCredentials'])

    return {
      checkDebtorData,
      filteredCredentials,
      checkDebtor,
      v$,
      credentialMask,
      credentialPlaceholder,
    }
  },
}
</script>

<style scoped lang="scss"></style>
