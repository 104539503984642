const credentialsValidationMap = {
  1: {
    minLength: 10,
    mask: '#'.repeat(10),
    placeholder: 'РНОКПП',
  },
  2: {
    minLength: 14,
    mask: '#'.repeat(14),
    placeholder:
      'Унікальний номер запису в Єдиному державному демографічному реєстрі',
  },
  3: {
    minLength: 8,
    mask: '#'.repeat(8),
    placeholder: 'Серія та номер паспорта громадянина України у формі книжки',
  },
  4: {
    minLength: 8,
    mask: '#'.repeat(8),
    placeholder:
      'Серія та номер паспорта громадянина України для виїзду за кордон',
  },
  5: {
    minLength: 2,
    mask: '#'.repeat(50),
    placeholder: 'Реєстраційний код/код платника податків в країні реєстрації',
  },
  6: {
    minLength: 8,
    mask: '#'.repeat(8),
    placeholder: 'ЄДРПОУ',
  },
}

export { credentialsValidationMap }
