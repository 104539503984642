<template>
  <v-card elevation="1">
    <v-card-text>
      <b-server-table
        :request-func="checkDebtorList"
        :headers="checkDebtorListHeaders"
        :show-search="true"
        :show-filters="true"
        @click:row="
          $event => {
            if (userAccessRights.includes('debtorCheck.single')) {
              $router.push({
                name: 'check-debtor-single',
                params: { id: $event.id },
              })
            }
          }
        ">
        <template #filters>
          <b-server-filter-item
            v-slot="{ props }"
            query-key="debtorTypeId"
            directory="debtorTypes">
            <filter-select v-bind="props" label="Тип боржника"></filter-select>
          </b-server-filter-item>
          <b-server-filter-item
            v-slot="{ props }"
            query-key="reasonId"
            directory="checkReason">
            <filter-select
              v-bind="props"
              label="Причина перевірки"></filter-select>
          </b-server-filter-item>
          <b-server-filter-item
            v-slot="{ props }"
            directory="debtorCheckResult"
            query-key="resultId">
            <filter-select v-bind="props" label="Статус пошуку"></filter-select>
          </b-server-filter-item>
          <b-server-filter-item
            v-slot="{ props }"
            :query-key="['startDate', 'endDate']">
            <b-input-date
              v-bind="props"
              range
              label="Дата пошуку"
              bg-color="grey-lighten-4"
              model-value-format="iso"></b-input-date>
          </b-server-filter-item>
        </template>
        <template #item.created_at="{ item }">
          {{ new CustomDate(item.created_at).toString({ time: true }) }}
        </template>
        <template #item.company="{ item }">
          <span
            class="link"
            @click="
              $router.push({
                name: 'company-single',
                params: { id: item.company.id },
              })
            ">
            {{ item.company.shortName }}
          </span>
        </template>
        <template #item.author="{ item }">
          <span
            class="link"
            @click="
              $router.push({
                name: 'user-single',
                params: { id: item.author.id },
              })
            ">
            {{ getPersonName(item.author, { initials: true }) }}
          </span>
        </template>
      </b-server-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import {
  BServerTable,
  BServerFilterItem,
  BInputDate,
} from 'best-modules/components'
import { checkDebtorList } from '@/request/checkDebtor'
import { checkDebtorListHeaders } from '@/utils/tableHeaders'
import FilterSelect from '@/components/inputs/filter-select.vue'
import { getPersonName } from 'best-modules/utils'
import { storeToRefs } from 'pinia'
import { useUser } from '@/store/user'
import { CustomDate } from 'best-modules/utils'

export default {
  name: 'CheckDebtorList',
  components: {
    FilterSelect,
    BServerTable,
    BServerFilterItem,
    BInputDate,
  },
  data() {
    return {
      checkDebtorListHeaders,
      CustomDate,
    }
  },
  methods: { getPersonName, checkDebtorList },
  setup() {
    const { userAccessRights } = storeToRefs(useUser())

    return { userAccessRights }
  },
}
</script>

<style scoped lang="scss"></style>
