<template>
  <div>
    <h4>{{ title }}</h4>
    <div class="mt-2 d-flex flex-column gap-2">
      <slot name="field"></slot>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    title: { type: String },
  },
}
</script>

<style scoped lang="scss"></style>
