<template>
  <div class="cursor-default">
    <h1>Ласкаво просимо в CR 2.0 NBU!</h1>
    <span class="text-blue-grey-lighten-2">
      Наш сервіс допоможе вам легко заповнити та передати щомісячні звіти до
      Кредитного реєстру.
    </span>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss"></style>
