import { h, ref, watch } from 'vue'

function getBreadcrumbInstance() {
  const items = ref([])
  const cardLabel = ref(null)

  const clearItems = () => items.value.splice(0)

  const routesTrigger = ref(1)
  const routes = ref([])
  const setRoutes = r => {
    routesTrigger.value += 1
    routes.value.splice(0)
    routes.value.push(...r)
  }

  watch(
    () => routesTrigger.value,
    () => {
      clearItems()

      const hasCardLabel = routes.value.some(r => r.meta && r.meta.cardLabel)
      cardLabel.value = hasCardLabel ? cardLabel.value : null

      items.value.push(
        ...routes.value
          .filter(r => r.name !== 'main' && r.meta.title)
          .map(r => ({
            title: r.meta.title,
            to: { name: r.name },
          }))
      )
    }
  )

  const set = (customItems, params = { type: 'edit' }) => {
    setTimeout(() => {
      if (!customItems) return
      if (params.type === 'replace') clearItems()
      customItems.forEach(item => {
        if (items.value[item.index]) {
          Object.assign(items.value[item.index], item)
        } else {
          items.value.push(item)
        }
      })
    }, 0)
  }

  const setCardLabel = ({ component, props }) => {
    cardLabel.value = h(component, props)
  }

  return { set, items, setRoutes, setCardLabel, cardLabel }
}

const breadcrumbInstance = getBreadcrumbInstance()

const breadcrumbPlugin = {
  install(app) {
    app.config.globalProperties.$setBreadcrumb = breadcrumbInstance.set
    app.config.globalProperties.$setCardLabel = breadcrumbInstance.setCardLabel
  },
}

const useBreadcrumb = () => {
  return breadcrumbInstance
}

export { breadcrumbPlugin, useBreadcrumb }
