<template>
  <v-card elevation="12">
    <v-expansion-panels v-model="expandedReport" variant="accordion" flat>
      <package-progress-component
        v-for="packageProgress in packageProgressList"
        :key="packageProgress.report.id"
        :package-progress="packageProgress"></package-progress-component>
    </v-expansion-panels>

    <div v-if="isAllPackagesCompleted" class="d-flex justify-end">
      <v-btn variant="plain" @click="closeDialog('packageProgress')">
        Закрити
      </v-btn>
    </div>
  </v-card>
</template>

<script lang="ts">
import PackageProgressComponent from './PackageProgress.vue'
import { computed, inject, ref, watch } from 'vue'
import { PackageProgress, PackageProgressStatus } from '@/utils/types'
import { isPackageCompleted } from '@/components/permanent-dialog/package-progress/helpers'
import { omit } from 'lodash'
import { getPackageProgressList } from '@/request/packageProgress'
import {
  packageProgressNbuChannel,
  packageProgressSignatureChannel,
  packageProgressStatusChannel,
  socket,
} from '@/socket'
import { useUser } from '@/store/user'
import { closeDialogKey, openDialogKey } from '../injectionKeys'

export default {
  name: 'PackageProgressIndex',
  components: { PackageProgressComponent },
  setup() {
    const openDialog = inject(openDialogKey)
    const closeDialog = inject(closeDialogKey)

    const { userData } = useUser()
    const expandedReport = ref<number>(0)

    const packageProgressList = ref<PackageProgress[]>([])

    watch(packageProgressList.value, list =>
      list.length
        ? openDialog('packageProgress')
        : closeDialog('packageProgress')
    )

    const isAllPackagesCompleted = computed<boolean>(() => {
      return packageProgressList.value.reduce((acc, cur) => {
        return acc && isPackageCompleted(cur)
      }, true)
    })

    const isReportExist = (reportId: string): boolean => {
      return packageProgressList.value.map(p => p.report.id).includes(reportId)
    }

    const handleSocket = (
      statusType: 'signature' | 'nbuSend' | 'nbuGetStatus',
      status: PackageProgressStatus
    ) => {
      if (
        status.report.companyId !== userData.watch_company_user_role.companyId
      ) {
        return
      }

      if (!isReportExist(status.report.id)) {
        packageProgressList.value.push({
          report: status.report,
          signature: null,
          nbuSend: null,
          nbuGetStatus: null,
        })
      }

      const packageProgress = packageProgressList.value.find(
        p => p.report.id === status.report.id
      )
      packageProgress[statusType] = omit(status, ['report'])
    }

    getPackageProgressList().then(res => {
      packageProgressList.value.splice(0)
      packageProgressList.value.push(...res)

      socket.on(packageProgressSignatureChannel, res =>
        handleSocket('signature', res)
      )
      socket.on(packageProgressNbuChannel, res => handleSocket('nbuSend', res))
      socket.on(packageProgressStatusChannel, res =>
        handleSocket('nbuGetStatus', res)
      )
    })

    watch(
      () => userData.watch_company_user_role?.companyId,
      () => {
        getPackageProgressList().then(res => {
          packageProgressList.value.splice(0)
          packageProgressList.value.push(...res)
        })
      }
    )

    return {
      expandedReport,
      isAllPackagesCompleted,
      packageProgressList,
      closeDialog,
    }
  },
}
</script>

<style scoped lang="scss"></style>
