<template>
  <div class="wrapper">
    <div class="wrapper-container">
      <div class="wrapper-container__title">
        <span v-if="!files.length">
          <slot v-if="$slots['dropper-label']" name="dropper-label"></slot>
          <span v-else>{{ label }}</span>
        </span>
        <div v-else>
          <b v-if="title">{{ title }}:</b>
          <b v-else>Файл ключа:</b>
          <div v-for="file in files" :key="file.id">
            <b>{{ file.name }}</b>
          </div>
        </div>
      </div>
      <div class="wrapper-container__subtitle">
        <span v-if="!files.length">
          Підтримуються формати: {{ accept.join(', ') }}
        </span>
        <span v-else>Завантажити інший файл</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { FileAccept } from 'best-modules/components/inputs/input-file/selectFile/types'

export default {
  name: 'FileDropper',
  props: {
    files: { type: Array },
    accept: { type: Array as PropType<FileAccept[]> },
    label: { type: String },
    title: { type: String },
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  border: 1px dashed var(--grey);
  cursor: grab;
  &-container {
    gap: 20px;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    &__title {
      font-size: 17px;
    }
    &__subtitle {
      font-size: 14px;
      color: var(--grey);
    }
  }
}
</style>
