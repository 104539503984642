<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <h4
        style="
          display: block;
          width: 55%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        ">
        {{ nameUr }}
      </h4>
      <div class="d-flex align-center gap-2">
        <v-btn variant="flat" color="green" rounded="xs" readonly>
          Завантажити звіт
        </v-btn>
        <v-btn-toggle
          v-model="toggleTab"
          mandatory
          color="black"
          variant="flat"
          density="compact"
          style="outline: 1px solid"
          @update:model-value="updateToggleTab">
          <v-btn size="small" density="comfortable" value="cr-version-1">
            CR v.1
          </v-btn>
          <v-btn size="small" density="comfortable" value="cr-version-2">
            CR v.2
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <div>
      <span class="text-disabled">
        <strong style="color: green">Увага!</strong> У разі виникнення питань
        щодо змісту інформації - звертайтесь до банку-кредитора.
      </span>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    nameUr: {
      type: String,
      required: true,
    },
  },
  setup(_, { emit }) {
    const toggleTab = ref('cr-version-1')

    const updateToggleTab = val => {
      emit('updateToggleTab', val)
    }

    return {
      toggleTab,
      updateToggleTab,
    }
  },
}
</script>

<style scoped lang="scss"></style>
