<template>
  <v-layout
    v-if="userData.watch_company_user_role?.repository?.length"
    class="h-screen">
    <the-header></the-header>

    <b-sidebar-menu
      :loading="!isLoadedUser"
      :items="menuItems"
      :user="isLoadedUser ? userData : null"
      :list-props="{
        activeClass: 'sidebar-menu--active',
      }">
      <template #expanded="{ isExpanded }">
        <v-btn
          data-test="sidebar-menu-expanded-btn"
          :icon="`mdi-chevron-${isExpanded ? 'left' : 'right'}`"
          size="x-small"
          density="comfortable"
          color="grey"
          variant="flat">
        </v-btn>
      </template>
    </b-sidebar-menu>

    <v-main>
      <v-card-text>
        <template v-if="$route.name === 'home'">
          <b-section-loader :loading="!isLoadedUser">
            <v-card elevation="1">
              <v-card-text>
                <Breadcrumb />
              </v-card-text>
            </v-card>
            <b-global-loader :loading="$loading.isLoading('global')">
              <router-view :key="renderKey"></router-view>
            </b-global-loader>
          </b-section-loader>
        </template>
        <template v-else>
          <div>
            <b-section-loader :loading="!isLoadedUser">
              <div>
                <v-card class="mb-3" elevation="1">
                  <v-card-text>
                    <Breadcrumb />
                  </v-card-text>
                </v-card>
                <b-global-loader :loading="$loading.isLoading('global')">
                  <router-view :key="renderKey"></router-view>
                </b-global-loader>
              </div>
            </b-section-loader>
          </div>
        </template>
      </v-card-text>
      <permanent-dialog></permanent-dialog>
    </v-main>
  </v-layout>
  <div
    v-if="isLoadedUser && !userData.watchCompanyUserRoleId"
    class="d-flex justify-center w-100 align-center">
    У вас немає доступу
  </div>
</template>

<script lang="ts">
import TheHeader from './TheHeader.vue'
import { useUser } from '@/store/user'
import {
  BSidebarMenu,
  BSectionLoader,
  BGlobalLoader,
} from 'best-modules/components'
import { menuItems } from '@/router/menuItems'
import { useRoute } from 'vue-router'
import { ref, provide, watchEffect, watch, computed } from 'vue'
import { rerenderKey } from '@/injection-keys'
import { debounce } from 'lodash'
import { storeToRefs } from 'pinia'
import Breadcrumb from '@/components/Breadcrumb.vue'
import PermanentDialog from '@/components/permanent-dialog/PermanentDialog.vue'

export default {
  name: 'Default',
  components: {
    PermanentDialog,
    Breadcrumb,
    BSidebarMenu,
    BSectionLoader,
    TheHeader,
    BGlobalLoader,
  },
  setup() {
    const route = useRoute()
    const { userData, getUserData, userAccessRights } = useUser()
    const { isLoadedUser } = storeToRefs(useUser())

    const renderKey = ref<number>(1)
    let prevRouteName: string = ''
    let prevRouteParams: string = ''

    const routeParamsToString = (params: object): string => {
      return Object.values(params).join('/')
    }

    watchEffect(() => {
      if (
        prevRouteName === route.name &&
        prevRouteParams !== routeParamsToString(route.params)
      ) {
        rerender()
      }
      prevRouteName = route.name as string
      prevRouteParams = routeParamsToString(route.params)
    })

    const rerender = debounce(() => {
      console.log('rerender')
      renderKey.value += 1
    }, 100)

    provide(rerenderKey, rerender)

    getUserData().then(() => {
      watch(
        computed(() => userData.id),
        rerender
      )
    })

    return {
      menuItems,
      userData,
      renderKey,
      isLoadedUser,
      userAccessRights,
    }
  },
}
</script>

<style lang="scss">
.sidebar-menu--active {
  border: 1px solid rgb(var(--v-theme-primary));
  background: var(--surface);
  & .v-list-item-title {
    font-weight: 700 !important;
  }
  & .v-list-item__overlay {
    opacity: 0 !important;
  }
}
</style>
