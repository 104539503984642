<template>
  <div>
    <div
      class="d-flex align-center gap-2 py-1 justify-space-between"
      style="cursor: pointer"
      :style="{
        paddingLeft: border ? '10px' : null,
      }"
      @click="toggleExpanded">
      <slot name="prepend-label"></slot>
      <slot v-if="$slots.label" name="label"></slot>
      <h3 v-else>
        {{ label }}
      </h3>
      <slot name="append-label"></slot>
      <v-btn
        color="grey"
        width="28px"
        min-width="28px"
        rounded="sm"
        variant="outlined"
        height="28px">
        <v-icon color="black" size="small">
          {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-btn>
    </div>
    <v-scroll-x-transition>
      <div
        v-show="isExpanded"
        class="mt-2 overflow-x-hidden"
        :class="isExpanded ? 'd-flex flex-column gap-2 mb-2' : null">
        <slot name="default"></slot>
      </div>
    </v-scroll-x-transition>
  </div>
</template>

<script lang="ts">
import { ref, watch, provide, inject, isRef, Ref } from 'vue'

export default {
  name: 'CustomExpansionPanel',
  emits: ['update:modelValue'],
  props: {
    label: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'white',
    },
    border: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isExpanded: Ref<boolean> = ref(props.modelValue)
    provide('isExpanded', isExpanded)

    const toggleExpanded = () => {
      isExpanded.value = !isExpanded.value
      emit('update:modelValue', isExpanded.value)
    }

    watch(
      () => props.modelValue,
      newValue => {
        isExpanded.value = newValue
      }
    )

    const isParentExpanded: Ref<boolean> | boolean = inject('isExpanded', false)
    if (isRef(isParentExpanded)) {
      watch(isParentExpanded, v => {
        if (!v) {
          isExpanded.value = false
          emit('update:modelValue', false)
        }
      })
    }

    return { isExpanded, toggleExpanded }
  },
}
</script>
