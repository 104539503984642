<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="13"
    viewBox="0 0 11 13"
    fill="none">
    <path
      d="M1.375 11.5556H3.4375V7.22222H7.5625V11.5556H9.625V5.05556L5.5 1.80556L1.375 5.05556V11.5556ZM0 13V4.33333L5.5 0L11 4.33333V13H6.1875V8.66667H4.8125V13H0Z"
      fill="#1F1F1F" />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss"></style>
