<template>
  <v-card>
    <v-card-text>
      <b-section-loader :loading="$loading.isLoading('userSingle')">
        <b-form
          :submit="{ title: 'Зберегти', handler: submit }"
          :reject="{
            title: 'Відхилити зміни',
            handler: () => user.$reset(),
          }"
          :actions-disabled="
            !user.$hasChanges || !userAccessRights.includes('user.update')
          ">
          <v-tabs v-model="activeTab" density="comfortable">
            <v-tab :value="0">Загальні дані</v-tab>
            <v-tab :value="1">Двохфакторна аутентифікація</v-tab>
            <v-tab :value="2">Налаштування паролю</v-tab>
            <v-tab v-if="userData.isAdmin" :value="3">Компанії</v-tab>
          </v-tabs>
          <v-divider></v-divider>
          <div class="my-8">
            <v-row v-if="activeTab === 0">
              <v-col cols="12" md="3" sm="12">
                <div class="user-photo">
                  <v-hover>
                    <template #default="{ props, isHovering }">
                      <div v-bind="props">
                        <BaseImage
                          :width="150"
                          :height="150"
                          aspect-ratio="1"
                          rounded
                          :src="user.photo ? user.photo[0] : null" />
                        <v-fade-transition>
                          <v-btn
                            v-show="isHovering"
                            class="user-photo-edit"
                            icon
                            size="x-small"
                            @click="changeUserPhoto">
                            <v-icon>{{ 'mdi-pencil' }}</v-icon>
                          </v-btn>
                        </v-fade-transition>
                      </div>
                    </template>
                  </v-hover>
                </div>
              </v-col>
              <v-col cols="12" md="9" sm="12">
                <v-row class="align-center">
                  <v-col cols="12" md="4">
                    <b-input
                      v-model="user.surname"
                      label="Прізвище"
                      hide-details
                      placeholder="Введіть текст "
                      :error-messages="getErrorMessages(v$.surname)"
                      @blur="v$.surname.$touch()"></b-input>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-switch
                      v-model="user.isActive"
                      class="mt-3"
                      color="primary"
                      hide-details
                      inset
                      :loading="
                        $loading.isLoading(['userDeactivate', 'userActivate'])
                      "
                      :disabled="
                        $loading.isLoading([
                          'userDeactivate',
                          'userActivate',
                        ]) ||
                        !userAccessRights.includes('user.activate') ||
                        !userAccessRights.includes('user.deactivate')
                      "
                      :label="user.isActive ? 'Активний' : 'Неактивний'"
                      @update:model-value="
                        $event ? activateUser(user.id) : deactivateUser(user.id)
                      "></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <b-input
                      v-model="user.name"
                      label="Ім'я"
                      hide-details
                      placeholder="Введіть текст "
                      :error-messages="getErrorMessages(v$.name)"
                      @blur="v$.name.$touch()"></b-input>
                  </v-col>
                  <v-col cols="12" md="4">
                    <b-select
                      v-model="companyId"
                      label="Компанія"
                      :disabled="true"
                      :readonly="true"
                      hide-details
                      :items="$directory.get('companyList', userCompany)"
                      item-value="id"
                      item-title="shortName"
                      :loading="$loading.isLoading('companyList')"
                      @focus="$directory.fill('companyList')"></b-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <b-input
                      v-model="user.patronymic"
                      placeholder="Введіть текст"
                      hide-details
                      label="По батькові"></b-input>
                  </v-col>
                  <v-col cols="12" md="4">
                    <b-input
                      v-model="user.email"
                      v-mask:[emailMask]
                      label="Email"
                      hide-details
                      type="email"
                      :error-messages="getErrorMessages(v$.email)"
                      @blur="v$.email.$touch()"></b-input>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="activeTab === 1" class="flex-column d-flex">
              <v-col cols="12" md="3" sm="12">
                <div v-if="!changePhone" class="d-flex flex-column">
                  <span class="label">Телефон</span>
                  <span>{{ user.phone }}</span>
                </div>
                <b-input-phone
                  v-else
                  v-model="user.phone"
                  label="Телефон"
                  hide-details
                  :error-messages="getErrorMessages(v$.phone)"
                  @blur="v$.phone.$touch()"></b-input-phone>
                <span
                  class="link text-decoration-underline mt-3"
                  @click="togglePhone"
                  >{{
                    !changePhone ? 'Змінити телефон' : 'Скасувати зміну'
                  }}</span
                >
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <v-checkbox
                  v-model="user['2FA']"
                  color="primary"
                  :true-value="true"
                  :false-value="false"
                  style="margin-left: -10px"
                  hide-details>
                  <template #label>
                    <span class="checkbox-label">2FA Google Authenticator</span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="activeTab === 2" class="flex-column">
              <v-col cols="12" md="4">
                <b-input-password
                  v-model="oldPassword"
                  label="Старий пароль"
                  hide-details
                  type="password"
                  placeholder="********"></b-input-password>
              </v-col>
              <v-col cols="12" md="4">
                <b-input-password
                  v-model="newPassword"
                  label="Новий пароль"
                  hide-detailsx
                  type="password"
                  placeholder="********"></b-input-password>
                <small
                  v-if="newPassword && !isPasswordValid"
                  class="text-red-accent-3">
                  Мінімум 8 символів, повинен містити літери верхнього та
                  нижнього регістра, цифри та спеціальні символи.
                </small>
                <small
                  v-if="
                    !isPasswordMatch &&
                    isPasswordValid &&
                    newPassword !== oldPassword
                  "
                  class="text-red-accent-3">
                  Новий пароль не повинен співпадати зі старим паролем.
                </small>
              </v-col>
              <v-col
                v-if="
                  isPasswordMatch &&
                  isPasswordValid &&
                  newPassword === oldPassword
                "
                cols="12"
                md="3"
                class="pt-0">
                <v-btn
                  :disabled="
                    !isPasswordValid ||
                    !isPasswordMatch ||
                    !userAccessRights.includes('user.changePassword')
                  "
                  @click="changePassword"
                  >Змінити пароль</v-btn
                >
              </v-col>
            </v-row>
            <v-row v-if="activeTab === 3">
              <v-col cols="12" md="12" sm="12">
                <div class="d-flex justify-end">
                  <v-btn
                    v-if="userAccessRights.includes('user.createByCompany')"
                    @click="createUserCompany">
                    <span>Додати компанію</span>
                    <v-icon class="ml-2">mdi-plus</v-icon>
                  </v-btn>
                </div>

                <v-data-table
                  :headers="userCompaniesTHead"
                  :items-per-page="-1"
                  :items="user.company_user_roles || []">
                  <template #bottom> </template>
                  <template #[`item.role`]="{ item }">
                    <span>{{ item.role }}</span>
                  </template>
                  <template #[`item.actions`]="{ item }">
                    <b-action-menu
                      :actions="[
                        {
                          title: 'Видалити',
                          icon: 'mdi-delete-outline',
                          action: () => deleteUserCompany(item.id),
                        },
                      ]"></b-action-menu>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </b-form>
      </b-section-loader>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import {
  BForm,
  BInputPhone,
  BSectionLoader,
  BInputPassword,
  BActionMenu,
} from 'best-modules/components'
import {
  getUserSingle,
  activateUser,
  deactivateUser,
  updateUser,
  changeUserPassword,
} from '@/request/user'
import {
  cachedObject,
  getErrorMessages,
  emailMask,
  getPersonName,
} from 'best-modules/utils'
import { useRoute } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, email } from '@vuelidate/validators'
import { useUser } from '@/store/user'
import { computed, ref } from 'vue'
import { setSnackbar } from 'best-modules/plugins/index.js'
import { openDialog } from '@/plugins/dialog'
import BaseImage from '@/components/BaseImage.vue'
import { userCompaniesTHead } from '@/utils/tableHeaders'
import { storeToRefs } from 'pinia'
import { useBreadcrumb } from '@/plugins/breadcrumb'
import { deleteUserCompanyRole } from '@/request/company'
import { User } from '@/utils/types'
import { DefaultItem } from 'best-modules/plugins'

export default {
  name: 'UserSingle',
  components: {
    BaseImage,
    BForm,
    BSectionLoader,
    BInputPhone,
    BInputPassword,
    BActionMenu,
  },
  data: () => {
    return {
      emailMask,
      activeTab: 0,
      userCompaniesTHead,
    }
  },
  methods: {
    getErrorMessages,
  },
  setup() {
    const route = useRoute()
    const userId = computed<string>(() => String(route.params.id))
    const oldPassword = ref('')
    const newPassword = ref('')
    const changePhone = ref(false)
    const { userData, setUserData } = useUser()
    const { userAccessRights } = storeToRefs(useUser())
    const breadcrumb = useBreadcrumb()

    const user = cachedObject({} as User)
    const setUser = obj => {
      user.$set(obj)
    }
    user.$setIgnore('isActive')
    user.$setIgnore('company_user_roles')

    const rules = {
      name: { required },
      surname: { required },
      phone: { required, minLength: minLength(23) },
      email: { required, email },
      password: { required },
    }

    const v$ = useVuelidate(rules, user)

    getUserSingle(userId.value).then(res => {
      setUser(res)
      return breadcrumb.set([
        {
          title: `Користувач ${getPersonName(user)}`,
          index: 1,
        },
      ])
    })

    const companyId = computed(() => {
      return user.watch_company_user_role
        ? user.watch_company_user_role.companyId
        : null
    })

    const userCompany = computed(() => {
      return user.watch_company_user_role
        ? (user.watch_company_user_role.company as DefaultItem)
        : null
    })

    const isPasswordValid = computed(() => {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
      return passwordRegex.test(newPassword.value)
    })

    const isPasswordMatch = computed(() => {
      return oldPassword.value === newPassword.value
    })

    const changePassword = () => {
      if (isPasswordValid.value && isPasswordMatch.value) {
        changeUserPassword(newPassword.value, userId.value).then(() => {
          oldPassword.value = ''
          newPassword.value = ''
        })
      } else {
        return setSnackbar({
          text: 'Пароль не відповідає вимогам або збігається зі старим паролем',
          color: 'error',
        })
      }
    }

    const createUserCompany = () => {
      openDialog({
        name: 'UserCompany',
        dialogData: {
          userId: user.id,
        },
        params: {
          cb: item => {
            setSnackbar({ text: 'Компанію додано', color: 'success' })
            user.company_user_roles.push(item)
          },
        },
      })
    }

    const togglePhone = () => {
      if (changePhone.value) {
        user.$reset()
      }
      changePhone.value = !changePhone.value
    }

    const changeUserPhoto = () => {
      openDialog({
        name: 'UserPhoto',
        params: {
          cb: photo => {
            if (photo) {
              user.photo = photo
            }
          },
        },
      })
    }

    const deleteUserCompany = id => {
      return deleteUserCompanyRole(id).then(() => {
        setSnackbar({ text: 'Компанію видалено', color: 'success' })
        user.company_user_roles = user.company_user_roles.filter(
          item => item.id !== id
        )
      })
    }

    const submit = async () => {
      v$.value.$touch()
      if (v$.value.$invalid) {
        await updateUser(user.$object).then(res => {
          if (user.id === userData.id) {
            setUserData(res)
          }
          setUser(res)
        })
      }
    }

    return {
      user,
      v$,
      submit,
      userData,
      companyId,
      userCompany,
      userAccessRights,
      activateUser,
      deactivateUser,
      oldPassword,
      newPassword,
      changePhone,
      togglePhone,
      isPasswordValid,
      isPasswordMatch,
      changePassword,
      changeUserPhoto,
      createUserCompany,
      deleteUserCompany,
    }
  },
}
</script>

<style scoped lang="scss">
.user-photo {
  position: relative;
  &-edit {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>
